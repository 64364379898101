import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LanguageIcon from '@mui/icons-material/Language';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ListIcon from '@mui/icons-material/MonetizationOn';
import SearchIcon from '@mui/icons-material/Search';
import TranslateIcon from '@mui/icons-material/Translate';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListItemLink from '@src/components/shared/ListItemLink';
import { IconType } from '@src/components/shared/Utils';
import {
    path as translationsPath,
    useTranslations,
} from '@src/components/translations/Translations';
import { useAuth } from '@src/hooks';
import { LanguageContext } from '@src/store';
import { toTitleCase } from '@src/utils';
import { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SettingsContext } from '../../modules/setting/SettingsContext';

type LinkToProps = {
    dest: string;
    disabled?: boolean;
    icon: IconType;
};

const LinkTo = ({ dest, icon, disabled }: LinkToProps) => {
    const intl = useIntl();
    return (
        <ListItemLink
            to={`/${dest}`}
            label={intl.formatMessage({
                id: dest,
                defaultMessage: toTitleCase(dest),
            })}
            icon={icon}
            disabled={disabled}
        />
    );
};

/**
 * Cool PIM Header [Language |  Products | Hierarchies]
 */
export function PimAppBar(): JSX.Element {
    const { defaultLanguage } = useContext(LanguageContext);
    const { enableSearch, theme: themeSettings } = useContext(SettingsContext);
    const { hasUserRole, sessionData } = useAuth();
    const hasLanguages = useMemo(() => !!defaultLanguage, [defaultLanguage]);
    const { canTranslate } = useTranslations();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const displayUserName =
        sessionData?.name || sessionData?.surname
            ? `${sessionData?.name} ${sessionData.surname}`
            : sessionData?.username;

    return (
        <AppBar
            sx={theme => ({
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            })}
        >
            <Toolbar
                sx={{
                    paddingRight: 24, // keep right padding when drawer closed
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                    }}
                >
                    <img
                        style={{ height: '32px', marginRight: '40px' }}
                        alt="CoolPim"
                        src={sessionData?.attributes?.logo ?? themeSettings?.logo ?? '/logo.png'}
                    />
                    <Box
                        sx={theme => ({
                            display: 'flex',
                            marginLeft: theme.spacing(1),
                        })}
                    >
                        {hasUserRole('PIM_ADMIN') && <LinkTo dest="language" icon={LanguageIcon} />}
                        {hasUserRole('PIM_ADMIN') && (
                            <LinkTo
                                dest="products"
                                icon={BusinessCenterIcon}
                                disabled={!hasLanguages}
                            />
                        )}
                        <LinkTo
                            dest="hierarchies"
                            icon={AccountTreeIcon}
                            disabled={!hasLanguages}
                        />
                        {hasUserRole('PIM_ADMIN') && (
                            <LinkTo dest="jobs" icon={WorkIcon} disabled={!hasLanguages} />
                        )}
                        {canTranslate && <LinkTo dest={translationsPath} icon={TranslateIcon} />}
                        {enableSearch && (
                            <LinkTo dest="search" icon={SearchIcon} disabled={!hasLanguages} />
                        )}
                        {hasUserRole('CGT_PRICELIST_DISCOUNT') && (
                            <LinkTo
                                dest="discounts"
                                icon={LocalOfferIcon}
                                disabled={!hasLanguages}
                            />
                        )}
                        {hasUserRole('CGT_PRICELIST') && (
                            <LinkTo dest="short_lists" icon={ListIcon} disabled={!hasLanguages} />
                        )}
                    </Box>
                </Box>
                {displayUserName && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            startIcon={<AccountCircle sx={{ color: 'white' }} />}
                            onClick={handleClick}
                        >
                            <Typography ml={1} color="white">
                                {displayUserName}
                            </Typography>
                        </Button>

                        <Menu
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/api/logout';
                                }}
                            >
                                <FormattedMessage defaultMessage="Logout" id="logout" />
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default PimAppBar;
