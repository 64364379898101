import { KeyboardArrowRight as BreadcrumbSeparator } from '@mui/icons-material/';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    Breadcrumbs,
    Divider,
    IconButton,
    lighten,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useAuth } from '@src/hooks';
import React from 'react';
import { useIntl } from 'react-intl';

import { SettingsContext } from '../setting/SettingsContext';
import { ContentTypeGroupStatuses } from './ContentTypeGroupStatuses';
import useStyles from './styles';
import { EdgeSelectedFn, NodeEdge, NodeViewMode } from './types';

type SingleNodeViewProps = {
    edge: NodeEdge;
    onEdgeSelected: EdgeSelectedFn;
    selectedNodeId: string | undefined;
    showCtgStatus: boolean;
    mode: NodeViewMode;
    isInTranslation?: boolean;
    showChildren?: boolean;
    showBreadcrumb?: boolean;
};

export const SingleNodeView = React.forwardRef<HTMLDivElement, SingleNodeViewProps>(
    (props, ref) => {
        const {
            edge,
            onEdgeSelected,
            mode,
            selectedNodeId,
            showCtgStatus,
            isInTranslation = false,
            showChildren = true,
            showBreadcrumb = false,
        } = props;

        const intl = useIntl();
        const { hasUserRole } = useAuth();
        const { nodeListEdgeBackground } = useStyles();

        const { showPublish } = React.useContext(SettingsContext);

        const isHierarchyViewMode = mode === 'hierarchy-viewer';

        const { node } = edge;

        const userHasPermissions =
            mode === 'change-parent'
                ? !!edge.node.writeRoles?.some(role => hasUserRole(role))
                : true;

        return (
            <Box paddingX={3}>
                {showBreadcrumb && (
                    <Breadcrumbs
                        separator={
                            <BreadcrumbSeparator
                                sx={{
                                    fill: t => lighten(t.palette.primary.light, 0.5),
                                    fontSize: t => t.typography.caption.fontSize,
                                }}
                            />
                        }
                        aria-label="breadcrumb"
                        sx={{ li: { mx: 0 } }}
                    >
                        {edge.node.breadcrumb?.slice(0, edge.node.breadcrumb.length - 1)?.map(b => (
                            <Typography
                                key={b.id}
                                color={t => lighten(t.palette.primary.light, 0.5)}
                                fontSize={t => t.typography.caption.fontSize}
                            >
                                {b.listName}
                            </Typography>
                        ))}
                    </Breadcrumbs>
                )}
                <Stack
                    ref={ref}
                    padding={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => {
                        if (userHasPermissions) {
                            onEdgeSelected(edge, false);
                        }
                    }}
                    sx={{
                        backgroundColor: nodeListEdgeBackground(
                            userHasPermissions,
                            selectedNodeId,
                            node.id,
                        ),
                        cursor: userHasPermissions ? 'pointer' : 'default',
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        {node.firstNodeStatus === 'notSynched' && (
                            <Tooltip
                                title={intl.formatMessage({
                                    id: 'hierarchy-notSynched.alert',
                                    defaultMessage: 'Non synched',
                                })}
                                placement="top-start"
                            >
                                <WarningIcon sx={{ width: '0.8em' }} color="warning" />
                            </Tooltip>
                        )}

                        <Tooltip
                            title={node.listName}
                            placement="top"
                            disableHoverListener={(node.listName?.length ?? 0) < 30}
                        >
                            <Typography
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                color={theme =>
                                    node.disabled || !userHasPermissions
                                        ? theme.palette.grey[400]
                                        : theme.palette.primary.main
                                }
                                fontSize="13px"
                                padding="5px"
                                fontWeight="600"
                            >
                                {node.listName}
                            </Typography>
                        </Tooltip>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap={1}>
                        {isHierarchyViewMode &&
                            !showCtgStatus &&
                            showPublish &&
                            !isInTranslation && (
                                <ContentTypeGroupStatuses status={node.contentTypeGroupStatus} />
                            )}

                        {isHierarchyViewMode && !isInTranslation && (
                            <IconButton
                                aria-label="edit"
                                size="small"
                                color="primary"
                                sx={theme => ({
                                    backgroundColor: theme.palette.grey[300],
                                })}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        )}

                        {(mode === 'change-parent' || mode === 'long-list-family-selection') &&
                            selectedNodeId === node.id &&
                            !isInTranslation && <CheckIcon fontSize="small" />}

                        {showChildren && (
                            <IconButton
                                aria-label="show children"
                                size="small"
                                onClick={e => {
                                    e.stopPropagation();
                                    onEdgeSelected(edge, true);
                                }}
                                color="primary"
                                sx={theme => ({
                                    backgroundColor: theme.palette.grey[300],
                                })}
                            >
                                <FormatListBulletedIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
                <Divider />
            </Box>
        );
    },
);
