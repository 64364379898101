import { Button, ButtonProps } from '@mui/material';
import { IconType } from '@src/components/shared/Utils';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';

type ListItemLinkProps = {
    icon?: IconType;
    label: string;
    to: string;
    variant?: ButtonProps['variant'];
    color?: ButtonProps['color'];
    disabled?: boolean;
};

const CustomLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(({ to, ...linkProps }, ref) => (
    <RouterLink ref={ref} to={to} {...linkProps} data-cy="router-link" />
));

const ListItemLink = ({
    icon: Icon,
    label,
    to,
    disabled,
    variant,
    color,
}: ListItemLinkProps): JSX.Element => {
    const location = useLocation();

    const active = location.pathname.includes(to);

    return (
        <Button
            color={color || 'inherit'}
            component={CustomLink}
            disabled={disabled}
            startIcon={Icon ? <Icon /> : null}
            sx={theme => ({
                marginRight: theme.spacing(1),
            })}
            variant={variant || (active ? 'outlined' : 'text')}
            to={to}
        >
            {label}
        </Button>
    );
};

export default ListItemLink;
