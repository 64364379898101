import { useContext } from 'react';

import BlockActionContext from './BlockActionContext';

const useBlockActionContext = () => {
    const blockActionContext = useContext(BlockActionContext);

    if (!blockActionContext) {
        throw new Error("Use 'BlockActionContext' inside it's provider");
    }

    return blockActionContext;
};

export default useBlockActionContext;
