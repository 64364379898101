import { createSvgIcon } from '@mui/material';

export const TranslateToggleIcon = createSvgIcon(
    <path
        d="M5.4,14.2l1.7-2h2v0.6H7.4L5.6,15c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0-0.5-0.2-0.5-0.5v-1.8H4.6H3.8c-1.2,0-2.1-1-2.1-2.1V6.9
	c0-1.2,0.9-2.1,2.1-2.1h8c1.2,0,2.1,1,2.1,2.1v3.9c0,1.1-0.8,2-1.9,2.1v-0.6c0.3,0,0.6-0.2,0.9-0.4c0.3-0.3,0.4-0.7,0.4-1V6.9
	c0-0.4-0.1-0.8-0.4-1c-0.3-0.3-0.6-0.4-1-0.4h-8c-0.8,0-1.4,0.7-1.4,1.5v3.9c0,0.4,0.1,0.8,0.4,1c0.3,0.3,0.6,0.4,1,0.5h1.6V14.2z
	 M20.2,9.8H15v0.6h5.3c0.4,0,0.8,0.2,1,0.5c0.3,0.3,0.4,0.7,0.4,1v3.9c0,0.4-0.1,0.8-0.4,1c-0.3,0.3-0.6,0.4-1,0.5h-1.7v2l-1.7-2
	h-4.7c-0.4,0-0.8-0.2-1-0.5c-0.3-0.3-0.4-0.7-0.4-1V12c0-0.4,0.1-0.8,0.4-1c0.2-0.2,0.5-0.4,0.9-0.4V9.8c-1.1,0.1-1.9,1-1.9,2.1v3.9
	c0,1.2,0.9,2.1,2.1,2.1h4.3h0.1l1.8,2.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0c0.2-0.1,0.3-0.2,0.3-0.4V18h1c1.1,0,2.1-1,2-2.1
	V12C22.3,10.8,21.4,9.8,20.2,9.8z M5.6,10.4l1.6-4.1l0.8,0l1.5,4.1H8.8l-0.3-1H6.6l-0.3,1H5.6z M6.8,9h1.6L7.7,7.2l0-0.1H7.5L6.8,9z
	 M17.7,11.7l0,0.4c-0.5,0.1-1.1,0.1-1.7,0.1l-0.1,0l0,0.1c0,0.1,0,0.4,0,0.7v0.2l0.2,0c0.2,0,0.4-0.1,0.6,0l0.1,0l0-0.1c0,0,0,0,0,0
	l0.5,0.1l0,0.1l0.1,0c0.5,0.1,0.9,0.6,0.9,1.1c0,0.5-0.2,1.2-1.6,1.4l-0.2-0.3c1.1-0.2,1.3-0.8,1.3-1.1c0-0.3-0.2-0.6-0.5-0.8
	l-0.1,0l-0.1,0.1c-0.2,0.4-0.6,0.9-0.9,1.2c-0.4,0.3-0.8,0.5-1.3,0.5c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.4-0.2
	c-0.1-0.1-0.2-0.3-0.1-0.4l0-0.1l0,0c0.1-0.6,0.5-1.2,1.1-1.4l0.1,0l0-0.1c0-0.2,0-0.6,0-0.8v-0.2l-0.3,0c-0.2,0-0.5,0-0.7,0l0-0.4
	h1l0-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2l0.5,0c0,0.1,0,0.3,0,0.4l0,0.2l0.2,0C16.7,11.9,17.2,11.8,17.7,11.7z M15.7,14.9
	l0-0.1c0-0.2-0.1-0.5-0.1-0.8l0-0.3l-0.2,0.1c-0.3,0.1-0.6,0.5-0.6,0.8c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0
	C15.3,15.1,15.4,15,15.7,14.9L15.7,14.9z M15.9,13.7l0,0.1c0,0.2,0,0.4,0,0.5l0,0.3l0.2-0.3c0.2-0.2,0.3-0.4,0.4-0.6l0.1-0.2l-0.3,0
	c-0.1,0-0.3,0-0.4,0L15.9,13.7z"
    />,
    'TranslateToggleIcon',
);
