import { styled } from '@mui/material/styles';
import { path as translationsPath } from '@src/components/translations/Translations';
import { useAuth } from '@src/hooks';
import Layout from '@src/Layout';
import { SettingsContext } from '@src/modules/setting/SettingsContext';
import { LanguageContext } from '@src/store';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    RouterProvider,
} from 'react-router-dom';

import { LinearLoader } from '../shared/LinearLoader';

const EditLanguage = lazy(() => import('@src/components/language/EditLanguage'));
const Language = lazy(() => import('@src/components/language/LanguageList'));
const NewLanguage = lazy(() => import('@src/components/language/NewLanguage'));
const Hierarchies = lazy(() => import('@src/modules/hierarchy/HierarchyViewer'));
const Products = lazy(() => import('@src/components/product/ProductInfoViewer'));
const JobList = lazy(() => import('@src/components/jobs/JobList'));
const Search = lazy(() => import('@src/modules/search/Search'));
const SearchResults = lazy(() => import('@src/modules/search/SearchResults'));
const Discounts = lazy(() => import('@src/modules/discounts/Discounts'));
const ShortLists = lazy(() => import('@src/modules/shortLists/ShortLists'));
const Translations = lazy(() => import('@src/components/translations/Translations'));

const classes = {
    loading: `loading`,
};

const Root = styled('p')(({ theme }) => ({
    [`&.${classes.loading}`]: {
        padding: theme.spacing(1),
    },
}));

function DelayedFallback(): JSX.Element | null {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 150);
        return () => clearTimeout(timeout);
    }, []);

    if (!show) {
        return null;
    }
    return (
        <Root className={classes.loading}>
            <LinearLoader />
        </Root>
    );
}

/**
 * App routes
 * "/language"                          >> LANGUAGE FRAME
 * "/language/new"                      >> NEW LANGUAGE FRAME
 * "/language/:id"                      >> EDIT LANGUAGE id
 * "/products                           >> PRODUCTS WORKFLOW FRAME
 * "/hierarchies/*"                     >> HIERARCHIES FRAME
 * "/translations"                      >> TRANSLATIONS FRAME
 * "/hierarchies/:hierarchyId/:nodeId"  >> EDIT TRANSLATIONS
 */
export function Routes(): JSX.Element {
    const { hasUserRole } = useAuth();
    const { languages } = useContext(LanguageContext);
    const { enableContentTranslation } = useContext(SettingsContext);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Layout />}>
                {hasUserRole('PIM_ADMIN') && (
                    <>
                        <Route path="language/new" element={<NewLanguage />} />
                        <Route path="language/:id" element={<EditLanguage />} />
                        <Route path="language" element={<Language />} />
                        <Route
                            path="products"
                            element={languages ? <Products /> : <Navigate to="/language" />}
                        />
                    </>
                )}
                <Route
                    path="hierarchies/*"
                    index
                    element={
                        languages ? (
                            <Hierarchies />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="search"
                    element={
                        languages ? (
                            <Search />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="discounts"
                    element={
                        languages ? (
                            <Discounts />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="short_lists"
                    element={
                        languages ? (
                            <ShortLists />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="search/results/:objectSchema"
                    element={
                        languages ? (
                            <SearchResults />
                        ) : (
                            <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/dashboard'} />
                        )
                    }
                />
                <Route
                    path="*"
                    element={
                        <Navigate to={hasUserRole('PIM_ADMIN') ? '/language' : '/hierarchies'} />
                    }
                />
                <Route
                    path="jobs"
                    element={languages ? <JobList /> : <Navigate to="/language" />}
                />

                {enableContentTranslation && (
                    <>
                        <Route path={translationsPath} element={<Translations />} />
                        <Route
                            path={`${translationsPath}/:hierarchyId/:nodeId`}
                            element={<Translations />}
                        />
                    </>
                )}
            </Route>,
        ),
    );

    // let span = webTracerWithZone.startSpan(`Router`, contenxt.active());
    // router.subscribe(routeState => {
    //     if (span) {
    //         span.end();
    //     }
    //     span = webTracerWithZone.startSpan(`Router`);
    // });

    return (
        <Suspense fallback={<DelayedFallback />}>
            <RouterProvider router={router} />
        </Suspense>
    );
}

export default Routes;
