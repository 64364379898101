import { useTheme } from '@mui/material';

export default function useStyles() {
    const theme = useTheme();

    const nodeListEdgeBackground = (
        userHasPermissions: boolean,
        selectedNodeId: string | undefined,
        currentNodeId: string | undefined,
    ) => {
        if (!userHasPermissions) return theme.palette.grey[100];
        if (selectedNodeId === currentNodeId) return theme.palette.grey[300];
        return undefined;
    };

    return { nodeListEdgeBackground };
}
