import { Box, LinearProgress, Stack, SxProps, Theme } from '@mui/material';

type ILinearLoaderProps = {
    sx?: SxProps<Theme>;
    message?: string;
};

export function LinearLoader({ sx, message }: ILinearLoaderProps) {
    return (
        <Stack
            direction="column"
            height={1}
            width={1}
            alignItems="center"
            justifyContent="center"
            position="fixed"
            sx={sx}
        >
            <Box sx={{ width: '60px', m: '0 auto' }}>
                <LinearProgress />
                {message}
            </Box>
        </Stack>
    );
}
