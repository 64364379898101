import { editNodeReducer } from '@src/store/hierarchy/EditNodeContext';
import { useMemo, useReducer } from 'react';

export default function useEditNode() {
    const [state, dispatch] = useReducer(editNodeReducer, null);

    const editNodeContextValue = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch],
    );

    return {
        editNodeContextValue,
    };
}
