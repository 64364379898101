import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import BlockActionContext, { DialogOptions } from './BlockActionContext';
import useBlockActionContext from './BlockActionContext.hook';

function BlockActionDialog() {
    const { open, setOpen, dialogOptions } = useBlockActionContext();

    const { title, description, confirm, cancel, confirmAction, cancelAction, actionsPosition } =
        dialogOptions;

    return (
        <Dialog open={open} onClose={() => setOpen(false)} sx={{ zIndex: 2001 }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{description}</DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    gap={1}
                    width="100%"
                    justifyContent={actionsPosition ?? 'end'}
                >
                    <Button onClick={() => cancelAction?.()}>{cancel}</Button>
                    <Button variant="contained" onClick={() => confirmAction?.()}>
                        {confirm}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

const BlockActionContextProvider = ({ children }: { children: ReactNode }) => {
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const [dialogOptions, setDialogOptions] = useState<DialogOptions>({
        title: intl.formatMessage({
            id: 'block-action.default-title',
            defaultMessage: 'Unsaved changes',
        }),
        description: intl.formatMessage({
            id: 'block-action.default-description',
            defaultMessage: 'If you agree to proceed, every unsaved changes will be lost.',
        }),
        confirm: intl.formatMessage({
            id: 'block-action.default-confirm',
            defaultMessage: 'Proceed',
        }),
        cancel: intl.formatMessage({
            id: 'block-action.default-cancel',
            defaultMessage: 'Cancel',
        }),
        cancelAction: () => setOpen(false),
    });

    const blockAction = (
        action: () => void,
        blockIf: boolean,
        newDialogOptions?: DialogOptions,
    ) => {
        if (blockIf) {
            const actionToPerform = () => {
                action();
                setOpen(false);
            };
            setDialogOptions({
                title: intl.formatMessage({
                    id: 'block-action.default-title',
                    defaultMessage: 'Unsaved changes',
                }),
                description: intl.formatMessage({
                    id: 'block-action.default-description',
                    defaultMessage: 'If you agree to proceed, every unsaved changes will be lost.',
                }),
                confirm: intl.formatMessage({
                    id: 'block-action.default-confirm',
                    defaultMessage: 'Proceed',
                }),
                cancel: intl.formatMessage({
                    id: 'block-action.default-cancel',
                    defaultMessage: 'Cancel',
                }),
                cancelAction: () => setOpen(false),
                ...newDialogOptions,
                confirmAction: actionToPerform,
            });
            setOpen(true);
        } else action();
    };

    const contextValue = useMemo(
        () => ({
            open,
            setOpen,
            blockAction,
            dialogOptions,
        }),
        [open, blockAction, dialogOptions],
    );

    return (
        <BlockActionContext.Provider value={contextValue}>
            {children}
            <BlockActionDialog />
        </BlockActionContext.Provider>
    );
};

export default BlockActionContextProvider;
