import { ReactNode } from 'react';

import { useSettingsQuery } from './settingQuery.generated';
import { getSettingContextDefaultValue, SettingsContext } from './SettingsContext';

export function SettingProvider({ children }: { children: ReactNode }) {
    const { data } = useSettingsQuery();

    return (
        <SettingsContext.Provider value={data?.settings ?? getSettingContextDefaultValue()}>
            {children}
        </SettingsContext.Provider>
    );
}
