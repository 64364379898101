export const toKeyValue = <T, V = T>(
    key: (val: T) => string,
    value: (val: T) => V,
    array: T[],
): Record<string, V> =>
    array
        .map(c => ({ [key(c)]: value(c) }))
        .reduce<Record<string, V>>((acc, v) => ({ ...acc, ...v }), {});

export const assertNever = (x: any) => {
    throw new Error(`Invalid value: ${JSON.stringify(x)}`);
};

export const DETONATE_ERRORS = {
    PRODUCT_INFO_NOT_SELECTED: 'PRODUCT_INFO_NOT_SELECTED',
    CONTENT_TYPE_GROUP_NOT_SELECTED: 'CONTENT_TYPE_GROUP_NOT_SELECTED',
    MISSING_NODE_ID: 'MISSING_NODE_ID',
    MISSING_HIERARCHY: 'MISSING_HIERARCHY',
    MISSING_CONTENT_TYPE: 'MISSING_CONTENT_TYPE',
    MISSING_CONTENT_TYPE_GROUP: 'MISSING_CONTENT_TYPE_GROUP',
    LANGUAGES_NOT_FOUND: 'LANGUAGES_NOT_FOUND',
} as const;

export const detonate = (cause: any): never => {
    throw new Error(cause);
};
