import React, { useMemo, useState } from 'react';

interface ContextProviderType {
    resolve?: boolean;
    setResolve?: (value: boolean) => void;
}

export const ConfirmContext = React.createContext<ContextProviderType | null>(null);

export const ConfirmContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [resolve, setResolve] = useState(false);
    const value = useMemo(() => ({ resolve, setResolve }), [resolve, setResolve]);
    return <ConfirmContext.Provider value={value}>{children}</ConfirmContext.Provider>;
};
