import { useLanguagesQuery } from '@src/components/language/language.generated';
import { LanguageContext } from '@src/store';
import { ReactNode, useMemo } from 'react';

function LanguageProvider({ children }: { children: ReactNode }) {
    const { data, refetch } = useLanguagesQuery();

    const contextValue = useMemo(() => {
        if (!data || !data.languages?.length || !data.languages[0]) {
            return null;
        }
        return {
            languages: data.languages[0].languages,
            defaultLanguage: data.languages[0].defaultLanguage,
            refetch,
        };
    }, [data]);

    if (!contextValue) {
        return null;
    }

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
}

export default LanguageProvider;
