import { IAuthContext } from '@src/assets/types';
import { AuthContext } from '@src/store';
import { useContext } from 'react';

export function useAuth() {
    const authContext = useContext(AuthContext) as IAuthContext;

    if (authContext === null) {
        throw new Error('useAuth() can only be used if the provider has been initialized');
    }

    return authContext;
}

export default useAuth;
