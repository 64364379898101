import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Language } from '@src/__generated__/types';
import { createContext } from 'react';

interface LanguageContextI {
    defaultLanguage: string;
    languages: Language['languages'] | null;
    refetch:
        | ((
              variables?: Partial<OperationVariables> | undefined,
          ) => Promise<ApolloQueryResult<Language>>)
        | (() => void);
}

const LanguageContext = createContext<LanguageContextI>({
    languages: null,
    defaultLanguage: '',
    refetch: () => ({}),
});

export default LanguageContext;
