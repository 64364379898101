import { IAuthContext, THasUserRole } from '@src/assets/types/auth';
// import { useCommon } from '@src/hooks';
import { ReactNode, useCallback, useState } from 'react';
import { getCookie, removeCookie } from 'typescript-cookie';

import AuthContext from './Auth.context';

// type ISessionData = IAuthContext['sessionData'];

interface ICoolsalesProviderProps {
    children: ReactNode;
    redirectUrl?: string; // if it is valued when auth request fails a redirect is made to this url. default undefined
    authKey?: string; // default Authorization
    authValue?: string; // token includes prefix. default Cookie[authKey]
    authUrl: string;
}

const CoolsalesAuthProvider = (props: ICoolsalesProviderProps) => {
    const { authKey = 'Authorization', authUrl, redirectUrl, authValue, children } = props;
    const errorMsg = `Initialization failed '${authUrl}'`;

    const token = authValue || (authKey && getCookie(authKey));
    const [userRoles, setUserRoles] = useState<string[]>();

    const [error, setError] = useState<Error | null>(null);
    const [isReady, setIsReady] = useState(false);
    const [sessionData, setSessionData] = useState<IAuthContext['sessionData'] | undefined>(
        undefined,
    );

    const logout = () => {
        if (authKey) removeCookie(authKey);
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    };

    const handlerOnError = (err: Error) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setError(err);
        logout();
    };

    const getContext = useCallback<
        (config: { initialize: VoidFunction; hasUserRole: THasUserRole }) => IAuthContext
    >(
        ({ hasUserRole, initialize }) => ({
            logout,
            error,
            isReady,
            // token,
            sessionData,
            // appRoles,
            authKey,
            hasUserRole,
            initialize,
        }),
        [isReady, error],
    );

    const hasUserRole = (roleName: string) => !!userRoles?.includes(roleName);

    const initialize = async () => {
        try {
            const response = await fetch(authUrl, {
                method: 'GET',
                headers: new Headers({
                    ...(token ? { [authKey]: token } : {}),
                }),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    window.location.href = '/api/login';
                } else {
                    const err = new Error(`${errorMsg} - status: ${response.status}`);
                    handlerOnError(err);
                }
                return;
            }

            const data = await response.json();

            setSessionData(data);
            setUserRoles(data.roles);
            setError(null);
            setIsReady(true);
        } catch (err: any) {
            setSessionData(undefined);
            err.message = `${errorMsg} - ${err.message}`;
            handlerOnError(err);
        }
    };

    const ctx = getContext({
        hasUserRole,
        initialize,
    });

    return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

export default CoolsalesAuthProvider;
