import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
    useSnackbarRef = useSnackbar();
    return null;
};

/**
 * Wrapper for adding message on the Notistack messages queue
 * @param msg message string which explains the feedback should be displayed
 */
export default {
    success(msg: string) {
        this.toast(msg, 'success');
    },
    warning(msg: string) {
        this.toast(msg, 'warning');
    },
    info(msg: string) {
        this.toast(msg, 'info');
    },
    error(msg: string) {
        this.toast(msg, 'error');
    },
    toast(msg: string, variant: VariantType = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, { variant, preventDuplicate: true });
    },
};
