import { Paper, Stack } from '@mui/material';
import { SelectedNode } from '@src/modules/hierarchy/types';
import { SettingsContext } from '@src/modules/setting/SettingsContext';
import { useContext, useState } from 'react';

import TranslateNode from './TranslateNode';
import TranslationsNavigation from './TranslationsNavigation';

export const path = 'translations';

export const encodeParam = (param: string) => btoa(encodeURIComponent(param));

export function useTranslations() {
    const { enableContentTranslation } = useContext(SettingsContext);
    return {
        canTranslate: enableContentTranslation, // TODO: check if user can translate
    };
}

export default function Translations(): JSX.Element {
    const [selectedNode, setSelectedNode] = useState<SelectedNode | undefined>();

    return (
        <Stack direction="row" sx={{ height: '100%' }}>
            <TranslationsNavigation selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
            <Paper
                sx={{
                    height: '100%',
                    width: '75%',
                    overflow: 'hidden scroll',
                    boxShadow: theme => theme.shadows[5],
                }}
            >
                <TranslateNode selectedNode={selectedNode} />
            </Paper>
        </Stack>
    );
}
