import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';

import { PimAppBar } from './components/layout/PimAppBar';

function Layout(): JSX.Element {
    return (
        <Box>
            <CssBaseline />
            <PimAppBar />
            <Box
                sx={{
                    flexGrow: 1,
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    marginTop: '64px', // TODO this is the height of the appBar
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}

export default Layout;
