import useEditNode from '@src/modules/hierarchy/hooks/useEditNode';
import { SelectedNode } from '@src/modules/hierarchy/types';
import { SettingsContext } from '@src/modules/setting/SettingsContext';
import { EditNodeContext } from '@src/store/hierarchy/EditNodeContext';
import { lazy, useContext } from 'react';

const EditNode = lazy(() => import('@src/components/hierarchies/EditNode'));

interface ITranslateNodeProps {
    selectedNode: SelectedNode | undefined;
}

export default function TranslateNode(props: Readonly<ITranslateNodeProps>): JSX.Element {
    const { selectedNode } = props;
    const { editNodeContextValue } = useEditNode();
    const { enableContentTranslation } = useContext(SettingsContext);

    return (
        <EditNodeContext.Provider value={editNodeContextValue}>
            {selectedNode && (
                <EditNode
                    hierarchyId={selectedNode.hierarchy}
                    nodeId={selectedNode.id}
                    isInTranslation={enableContentTranslation}
                    navigateAndRefreshNodeList={() => {}}
                    refreshNodeList={() => {}}
                />
            )}
        </EditNodeContext.Provider>
    );
}
