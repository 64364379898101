import './index.css';

// import './tracer';
import App from '@src/App';
import { CoolsalesAuthProvider } from '@src/store';
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

function getCookie(cname: string) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <CoolsalesAuthProvider
        authUrl="/api/user" // Required
        authValue={getCookie('Authorization')} // token includes prefix. default Cookie[authKey]
    >
        <App />
    </CoolsalesAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
